import React from "react";
import { Link } from "gatsby";

const Satellites = () => {
  return (
    <div className="space-y-5">
      <div>
        <h1 className="text-[#d83616] font-bold md:text-3xl text-2xl mt-5">
          Satellite Events
        </h1>
      </div>
      <div className="space-y-3">
        <p className="text-lg">
          ISMIR 2022 will be accompanied by several satellite events around the
          conference.
        </p>
        <p className="text-xl text-[#d83616] font-bold">
          <a href="https://wimir.wordpress.com/" target="_blank">
            WiMIR Workshop
          </a>
        </p>
        <p className="text-lg font-bold">
          November 28-29, 2022 online (virtual)
        </p>
        <p className="text-lg">
        The WiMIR Workshop will be two days of talks by eminent researchers in the WiMIR community, 
        and an opportunity to network, socialize, and have discussions with peers ahead of the ISMIR
         conference. The Workshop is, as ever, free and open to all members of the MIR community. 
         Full program of speakers coming soon. Registration will open in late October.
        </p>
        <br></br>
        <p className="text-xl text-[#d83616] font-bold">
          <Link to="/satellites/mmmt">
            Music, Mind, Movement and Technology (MMMT) Workshop
          </Link>
        </p>
        <p className="text-lg font-bold">
          Dec 2-3, 2022 in IISc, Bengaluru, India
        </p>
        <p className="text-lg">
          The Music, Mind, Movement and Technology (MMMT) Workshop is a hybrid
          satellite workshop around the 23rd International Society for Music
          Information Retrieval Conference (ISMIR 2022). MMMT is an attempt to
          increase the dialog between the fields of Music Information Retrieval
          and Music Cognition. This hybrid two-day workshop on Dec 2-3, 2022
          brings together leading International researchers for a series of
          talks highlighting interdisciplinary research and facilitating
          interaction and exchange of ideas around various themes. More
          information:{" "}
          <span className="text-[#d83616]">
            <a
              href="https://ismir2022.ismir.net/satellites/mmmt"
              target="_blank"
            >
              https://ismir2022.ismir.net/satellites/mmmt
            </a>
          </span>
        </p>
        <br></br>
        <p className="text-xl text-[#d83616] font-bold">
          <Link to="/satellites/ime">
            Indian Music Experience (IME) Workshop
          </Link>
        </p>
        <p className="text-lg font-bold">
          Dec 9, 2022 at the Indian Music Experience Museum, Bengaluru, India
        </p>
        <p className="text-lg">
          As a satellite event for ISMIR 2022, we are organizing an Indian music
          experience workshop on the 9th Dec, 2022. The day-long physical-only
          workshop will be hosted in the{" "}
          <span className="text-[#d83616]">
            <a href="https://indianmusicexperience.org/" target="_blank">
              Indian Music Experience Museum (IME)
            </a>
          </span>, which is India’s first interactive music museum and will involve the
          museum visit, workshops on Indian art music and a music exhibition.
          More information:{" "}
          <span className="text-[#d83616]">
            <a
              href="https://ismir2022.ismir.net/satellites/ime"
              target="_blank"
            >
              https://ismir2022.ismir.net/satellites/ime
            </a>
          </span>
        </p>
        <br></br>
        <p className="text-xl text-[#d83616] font-bold">MusicHackDay India 2022</p>
        <p className="text-lg font-bold">Dec 10-11, 2022 at 91 Springboard, Mahatma Gandhi Road, Bengaluru, India</p>
        <p className="text-lg">
          <span className="text-[#d83616]">
            <a href="https://musictechcommunity.org/" target="_blank">
              The Music Tech Community India
            </a>
          </span>{" "}
          is an open community of musicians, developers, researchers and artists
          with an aim to collaborate, share knowledge and bridge the gap between
          media, arts and technology as a community. The community conducts
          talks, workshops, seminars and are currently curating tutorials,
          resources and interviews with artists, startup founders and
          researchers. As a satellite event of ISMIR 2022, the community is
          organizing a 2-day MusicHackDay India 2022 event on 10-11 Dec, 2022
          that includes a hackathon, algorave concert, networking, and panel
          discussions. The event is planned to be open to public (with prior
          registration) and will be organized in Bengaluru, with a possibility
          of virtual participation. MusicHackDay India 2022 will include the
          following events:
          <ul className="list-disc list-inside">
            <li>
              HAMR (Hacking Audio Music Research): Extending on the tradition of
              past ISMIR conferences, MusicHackDay India will include a
              hackathon with prizes for the best hacks.
            </li>
            <li>
              Algorave concert: Live coding, audio-visual performance from our
              friends from the Algorave India community, coupled with a social
              event
            </li>
            <li>
              Networking: A great opportunity to connect the burgeoning tech and
              music community in Bengaluru, India with the diverse ISMIR
              community.
            </li>
            <li>
              Panel discussions/Interviews: A panel discussion between
              individuals from different dimensions of music tech from the
              Academia, Business and the Musicians.
            </li>
          </ul>
        </p>
        <p className="text-lg">
        More information: {" "}
          <span className="text-[#d83616]"><a href="https://musichackdayindia.github.io/" target="_blank">https://musichackdayindia.github.io/</a></span>
        </p>
        <br></br>
        <p className="text-xl text-[#d83616] font-bold">
          <a href="https://compmusic.upf.edu/node/365" target="_blank">
            CompMusic Workshop 2022
          </a>
        </p>
        <p className="text-lg font-bold">
          December 12-16, 2022 at IIT Madras, Chennai, India
        </p>
        <p className="text-lg">
          CompMusic Workshop 2022 is a 5-day workshop and a satellite event of
          ISMIR 2022 to introduce the field of Computational Musicology while
          focusing on the study of Carnatic Music. By combining theoretical
          lectures with hands-on labs, the workshop is aimed at giving the
          participants the conceptual framework and practical tools needed to
          analyze and understand music signals using a variety of computational
          methodologies. The workshop is aimed at undergraduate or graduate
          students doing engineering, music, or social sciences, without prior
          experience in the topic but highly interested in acquiring the
          computational and musicological competencies needed to study music, in
          particular Carnatic Music. The workshop will take place at the{" "}
          <span className="text-[#d83616]">
            <a href="https://www.iitm.ac.in/" target="_blank">
              Indian Institute of Technology Madras
            </a>
          </span>
          , Chennai during the{" "}
          <span className="text-[#d83616]">
            <a
              href="https://en.wikipedia.org/wiki/Madras_Music_Season"
              target="_blank"
            >
              Madras Music Season
            </a>
          </span>
          , which is the largest music festival of Carnatic Music. In the
          evenings, the participants will be able to attend a wide variety of
          Carnatic Music concerts. 
          More information:{" "}
          <span className="text-[#d83616]">
            <a href="https://compmusic.upf.edu/workshop-ismir-2022" target="_blank">
              https://compmusic.upf.edu/workshop-ismir-2022
            </a>
          </span>
          {" "}(Registrations for the workshop are now open. The workshop has a limited capacity of 30 participants).
        </p>
      </div>
    </div>
  );
};

export default Satellites;
